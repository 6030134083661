import gql from "graphql-tag";

export const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    name
    description(HTML: false)
    slug
    tags
    active
    seo {
      title
      description
    }
    collections {
      name
      slug
      metafields {
        id
        key
        value
        files {
          id
          url
        }
      }
    }
    images {
      id
      url
    }
    metafields {
      id
      key
      value
      files {
        id
        url
      }
    }
    variants(where: { available: true }, orderBy: sort_ASC) {
      id
      available
      price
      priceCompareAt
      name
      weight
      weightUnit
      sort
      product {
        name
      }
      image {
        id
        url
        altText
      }
      selectedOptions {
        id
        name
        value
      }
      metafields {
        id
        namespace
        key
        value
        files {
          id
          url
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products(
    $first: Int
    $where: CollectionWhereInput
    $productsWhere: ProductWhereInput
  ) {
    shop {
      id
      collections(where: $where) {
        id
        name
        slug
        seo {
          title
          description
        }
        image {
          id
          url
        }
        tags
        metafields {
          id
          description
          key
          namespace
          value
          values
          files {
            id
            url
          }
        }
        products(first: $first, where: $productsWhere, orderBy: sort_ASC) {
          ...ProductFragment
        }
      }
    }
  }
  ${ProductFragment}
`;

export const GET_PRODUCT = gql`
  query product($slugCollection: String!, $slugProduct: String) {
    shop {
      id
      collections(where: { slug: $slugCollection }) {
        id
        metafields {
          id
          description
          key
          namespace
          value
          values
          files {
            id
            url
          }
        }
        products(where: { slug: $slugProduct }) {
          ...ProductFragment
        }
      }
    }
  }
  ${ProductFragment}
`;

export const GET_TOPPINGS = gql`
  query toppings($id: ID!) {
    shop {
      id
      collections(where: { id: $id }) {
        id
        products(orderBy: sort_ASC) {
          sort
          ...ProductFragment
        }
      }
    }
  }
  ${ProductFragment}
`;
